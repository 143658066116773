import Swal from "sweetalert2";

const showAlert = (success) => {
  Swal.fire({
    title: success ? "Success!" : "Something happened",
    text: success
      ? "Action completed successfully"
      : "Action could not be handled at the moment. Please retry",
    icon: success ? "success" : "error",
    showCancelButton: false,
    confirmButtonColor: "#000000",
  });
};

const swalFire = (action) => {
  return Swal.fire(action);
};
export { showAlert, swalFire };
